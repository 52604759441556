@import 'vars'

footer.footer
  padding: $layout-padding
  margin: 8px 0
  color: #C4C4C4
  text-align: center
  font:
    weight: bold
    size: 24px

.logo_line
  display: flex
  gap: 0.4em
  align-items: center
  justify-content: center
  color: #000000
  font:
    weight: 400
    size: 16px
